'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useBuildId, usePrevious } from '@/hooks/useBuildId';
import { useAppDispatch } from '@/store/store';
import { buildIdAction } from '@/store/buildId';

export default function BuildIdChecker() {
    const { buildId } = useBuildId();
    const prevBuildId = usePrevious(buildId);
    const router = useRouter();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (prevBuildId && buildId && prevBuildId !== buildId) {
            dispatch(buildIdAction.updateBuildId(buildId));
            router.refresh();
        }
    }, [buildId, dispatch, prevBuildId, router]);

    return null;
}